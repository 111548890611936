import { observer, useLocalStore } from 'mobx-react';
import { runInAction } from 'mobx';
import React, { useEffect } from 'react';
import Axios from 'axios';
import { AgencyEntity, PlaylistEntity } from '../../../Models/Entities';
import alert from '../../../Util/ToastifyUtils';
import { store } from '../../../Models/Store';
import { TextField } from '../../Components/TextBox/TextBox';
import { Button, Colors, Display } from '../../Components/Button/Button';
import { PlaylistSummary } from '../../../Util/PlaylistUtils';
import SpotifyImportModal from './SpotifyImportModal';
import { SERVER_URL } from '../../../Constants';
import { Combobox } from '../../Components/Combobox/Combobox';
import { RadioButtonGroup } from 'Views/Components/RadioButton/RadioButtonGroup';
import FileUpload from 'Views/Components/FileUpload/FileUpload';
import M3UImportModal from './M3UImportModal';

interface NewPlaylistModalProps {
	openPlaylist: (playlist: PlaylistSummary) => void;
}

interface NewPlaylistForm {
	name: string;
	link?: string ;
	file?: File;
	import: string | boolean;
	errors: {
		agencyOwnerId?: string;
		name?: string;
		link?: string;
		file?: string;
	};
}

const defaultForm: NewPlaylistForm = {
	name: '',
	import: false,
	errors: {
		agencyOwnerId: '',
		name: '',
		link: '',
		file: '',
	},
};

const NewPlaylistModal = observer((props: NewPlaylistModalProps) => {
	const { openPlaylist } = props;
	const newPlaylistForm = useLocalStore(() => (defaultForm));
	const agencyStore = useLocalStore(() => ({
		agencies: [] as AgencyEntity[],
		agency: '',
	}));

	useEffect(() => {
		if (store.isMustardAdminOrMustardManager) {
			Axios.get(`${SERVER_URL}/api/entity/AgencyEntity/GetWithMustardAdminId`)
				.then(res => {
					runInAction(() => agencyStore.agencies = res.data.map((a: AgencyEntity) => new AgencyEntity(a)));
				})
				.catch(err => console.log('err'));
		}
	}, []);

	const validate = () => {
		if (newPlaylistForm.name === '') {
			runInAction(() => newPlaylistForm.errors.name = 'Name field is required');
		} else {
			runInAction(() => delete newPlaylistForm.errors.name);
		}

		if ((newPlaylistForm.import === 'link') && (!newPlaylistForm.link)) {
			runInAction(() => newPlaylistForm.errors.link = 'Spotify ID is required if Import from Spotify is checked');
		} else {
			runInAction(() => delete newPlaylistForm.errors.link);
		}

		if ((newPlaylistForm.import === 'file') && (!newPlaylistForm.file)) {
			runInAction(() => newPlaylistForm.errors.file = 'Please select an M3U file');
		} else {
			runInAction(() => delete newPlaylistForm.errors.file);
		}

		if (store.isMustardAdminOrMustardManager) {
			if (agencyStore.agency === '') {
				runInAction(() => newPlaylistForm.errors.agencyOwnerId = 'An agency is required');
			} else {
				runInAction(() => delete newPlaylistForm.errors.agencyOwnerId);
			}
		}
	};

	const onSubmit = async (e: any) => {
		e.preventDefault();

		validate();

		if (!newPlaylistForm.errors.name && !newPlaylistForm.errors.link && !newPlaylistForm.errors.file && !newPlaylistForm.errors.agencyOwnerId) {
			const agency = await store.getLoggedInAgency();
			const newPlaylist = new PlaylistEntity({
				name: newPlaylistForm.name,
			});

			if (store.isMustardAdminOrMustardManager) {
				if (agencyStore.agency !== '') {
					newPlaylist.agencyOwnerId = agencyStore.agency;
				}
			} else {
				newPlaylist.agencyOwnerId = agency.id;
			}

			if (newPlaylistForm.import === 'file') {
				store.modal.hide();
				store.modal.show(
					'Import from M3U file',
					<M3UImportModal name={newPlaylistForm.name} file={newPlaylistForm.file as File} openPlaylist={openPlaylist} />,
					{ className: 'slideout-panel-right' },
				);
				return;
			}

			if ((newPlaylistForm.import === 'link')) {
				store.modal.hide();
				store.modal.show(
					'Import from Spotify',
					<SpotifyImportModal name={newPlaylistForm.name} spotifyId={newPlaylistForm.link as string} openPlaylist={openPlaylist} agencyOwnerId={newPlaylist.agencyOwnerId} />,
					{ className: 'slideout-panel-right-lrg' },
				);
				return;
			}

			newPlaylist.save().then(res => {
				openPlaylist({
					id: newPlaylist.id,
					name: newPlaylist.name,
					summary: '0 TRACKS | NA',
				});
				alert('Created Playlist', 'success');
				store.modal.hide();
			}).catch(error => {
				alert('Could not create playlist', 'error');
			});
		}
	};

	return (
		<div className="new-playlist-form">
			<h4>New Playlist</h4>
			<form onSubmit={onSubmit}>
				{store.isMustardAdminOrMustardManager && (
					<Combobox
						model={agencyStore}
						modelProperty="agency"
						label="Agency"
						className="agency"
						options={agencyStore.agencies
							.map(a => ({ display: a.name, value: a.id }))
							.sort((a, b) => a.display.localeCompare(b.display))}
						isRequired
						errors={newPlaylistForm.errors.agencyOwnerId}
					/>
				)}
				<TextField
					model={newPlaylistForm}
					modelProperty="name"
					label="Name"
					className="name"
					placeholder="Name"
					isRequired
					errors={newPlaylistForm.errors.name}
				/>
				<div className="separator">
					<h4>Import Playlist</h4>
					<label className="switch">
						<input
							type="checkbox"
							onChange={() => runInAction(() => {
								if (newPlaylistForm.import !== false) {
									newPlaylistForm.import = false;
								} else {
									newPlaylistForm.import = 'link';
								}
							})}
							checked={newPlaylistForm.import !== false}
						/>
						<span className="slider round" />
					</label>
				</div>
				{newPlaylistForm.import && (
					<>
						<RadioButtonGroup
							name="Import"
							model={newPlaylistForm}
							modelProperty="import"
							options={[
								{ value: 'link', display: 'Spotify' },
								{ value: 'file', display: 'M3U' },
							]}
							className="import-radio"
						/>
						{(newPlaylistForm.import === 'link') && (
							<TextField
								model={newPlaylistForm}
								modelProperty="link"
								label="Import from Spotify"
								className="link"
								placeholder="Spotify Playlist ID"
								errors={newPlaylistForm.errors.link}
							/>
						)}
						{(newPlaylistForm.import === 'file') && (
							<FileUpload
								model={newPlaylistForm}
								modelProperty="file"
								buttonText="Upload M3U"
								disableDropArea
								// isDisabled={} check if it is processing already
								preview={(file): JSX.Element => {
									if (file) {
										return <div className="csv-label">{file.name}</div>;
									}
									return <></>;
								}}
								errors={newPlaylistForm.errors.file}
								contentType=".m3u"
							/>
						)}
					</>
				)}
				<div className="form-controls">
					<Button
						className="cancel"
						display={Display.Outline}
						colors={Colors.White}
						onClick={() => store.modal.hide()}
					>
						Cancel
					</Button>
					<Button
						type="submit"
						className="submit"
						display={Display.Solid}
						colors={Colors.Primary}
					>
						Create Playlist
					</Button>
				</div>
			</form>
		</div>
	);
});

export default NewPlaylistModal;
